<template>
  <div class="exam-content">
    <div class="row">
      <div class="col-md-12">
        <div class="exam-wrap">
          <div class="__header">
            <h4>{{ exam.title }}</h4>
            <p>TEST INSTRUCTION : CLICK OPTION (A, B, C, D)</p>
          </div>
          <div class="__body" v-if="isAlreadyAttended == false">
            <div class="main-head-line">
              <h5 v-if="isLoading == false">
                Question {{ questionIndex + 1 }}
              </h5>
              <p class="mark-paragraph pull-right">
                &nbsp; &#40;Mark: {{ question.mark }}&#41;
              </p>
            </div>
            <p class="question" v-html="question.question"></p>
            <div v-if="question.general_feedback != NULL">
              <p class="subnote">
                &#40; &nbsp;{{ question.general_feedback }}&#41;
              </p>
            </div>
            <div class="answer-list" v-if="isLoading == false">
              <ul class="list-unstyled">
                <li
                  v-for="(answer, answerIndex) in question.answers"
                  :key="answerIndex"
                >
                  <a
                    href="#"
                    :class="[
                      answer.id == selectedAnswer ? 'marked-answer' : '',
                      answer.grade_percentage == 100 ? 'active' : ''
                    ]"
                    ><span v-if="answerIndex == 0">A</span
                    ><span v-if="answerIndex == 1">B</span
                    ><span v-if="answerIndex == 2">C</span
                    ><span v-if="answerIndex == 3">D</span></a
                  >
                  <p>{{ answer.option }}</p>
                  <div v-if="answer.answer_feedback != NULL">
                    <p v-if="question.marked_answer == answer.id">
                      <br />
                      <span class="subnote">
                        &#40;{{ answer.answer_feedback }}&#41;
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
              <div></div>
            </div>
            <div class="button-wrap">
              <button
                class="cta-primary btn"
                v-if="questionIndex > 0"
                @click.prevent="previousQuestion()"
              >
                Previous
              </button>
              <button
                class="cta-primary btn"
                v-if="questionIndex < questions.length - 1"
                @click.prevent="nextQuestion()"
              >
                Next
              </button>
            </div>
          </div>
          <div class="__body" v-else>
            <p class="message-text">{{ message }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4" v-if="isAlreadyAttended == false">
        <div class="exam-wrap">
          <div class="__header">
            <h5>Question Overview</h5>
          </div>
          <div class="__body">
            <div class="inidicator-wrap">
              <ul class="list-unstyled">
                <li
                  v-for="(question, questionIndex) in questions"
                  :key="questionIndex"
                >
                  <a
                    :class="
                      attendedQuestion.includes(question.id) ? 'attended' : ''
                    "
                    href=""
                    >{{ questionIndex + 1 }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="indicator-info">
              <ul class="list-unstyled">
                <li>
                  Attended
                </li>
                <li>
                  Not Attended
                </li>
              </ul>
            </div>
            <div class="text-center mt-3">
              <button
                class="cta-primary btn m-auto"
                @click.prevent="submitExam(question.id)"
                :disabled="isSubmittingExam == true"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ExamService from "@/services/ExamService";
//import WebinarLoader from "@/components/loaders/WebinarLoader";
export default {
  name: "ExamPortal",
  components: {},
  created() {
    this.getExamResult();
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  data() {
    return {
      exam: [],
      questions: [],
      questionIndex: 0,
      question: "",
      answers: [],
      selectedAnswer: "",
      selectedAnswerArray: [],
      attendedQuestion: [],
      isSubmittingExam: false,
      isAlreadyAttended: false,
      isLoading: true,
      message: "",
      examId: this.$route.params.id
    };
  },
  methods: {
    getExamResult: function() {
      this.isLoading = true;
      ExamService.getExamResult({
        exam_id: this.examId
      })
        .then(result => {
          this.isLoading = false;
          if (result.data.status == "success") {
            this.questions = result.data.questions;
            this.exam = result.data.exam;
            this.getQuestion();
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    getQuestion: function() {
      if (this.questions.length > 0) {
        this.question = this.questions[this.questionIndex];
        this.selectedAnswer = this.question.marked_answer;
      } else {
        this.question = "";
      }
    },

    nextQuestion() {
      this.questionIndex += 1;
      this.getQuestion();
    },
    previousQuestion() {
      this.questionIndex -= 1;
      this.getQuestion();
    }
  }
};
</script>

<style lang="scss">
@import "@/style/exam-portal.scss";
</style>
